var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BRow', [_c('BCol', {
    staticClass: "d-flex-between pr-25 align-items-center",
    attrs: {
      "cols": "5"
    }
  }, [_c('HStack', [_vm._t("title")], 2), _vm._v(" : ")], 1), _c('BCol', {
    staticClass: "pl-25 d-flex align-items-center",
    attrs: {
      "cols": "7"
    }
  }, [_vm._t("content")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }