var render = function () {
  var _vm$tourSelected, _vm$tourSelected3, _vm$tourSelected11, _vm$tourSelected13, _vm$tourSelected15, _vm$tourSelected18;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.customClass
  }, [(_vm$tourSelected = _vm.tourSelected) !== null && _vm$tourSelected !== void 0 && _vm$tourSelected.tourCode ? _c('TourInfoLineLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('IAmIcon', {
          attrs: {
            "icon": "ticketLine",
            "size": "20",
            "color": "green"
          }
        }), _vm._v(" Mã chương trình ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        var _vm$tourSelected2;
        return [_c('span', {
          staticClass: "text-info fw-600 text-break"
        }, [_vm._v(_vm._s((_vm$tourSelected2 = _vm.tourSelected) === null || _vm$tourSelected2 === void 0 ? void 0 : _vm$tourSelected2.tourCode))])];
      },
      proxy: true
    }], null, false, 1609226300)
  }) : _vm._e(), _vm.defaultDepartPoint ? _c('TourInfoLineLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('IAmIcon', {
          attrs: {
            "icon": "locationOutline",
            "size": "20",
            "color": "green"
          }
        }), _vm._v(" Khởi hành ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-info fw-600 text-break"
        }, [_vm._v(_vm._s(_vm.defaultDepartPoint))])];
      },
      proxy: true
    }], null, false, 1243739877)
  }) : _vm._e(), (_vm$tourSelected3 = _vm.tourSelected) !== null && _vm$tourSelected3 !== void 0 && _vm$tourSelected3.levelNam ? _c('TourInfoLineLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('IAmIcon', {
          attrs: {
            "icon": "hotelLine",
            "size": "20",
            "color": "green"
          }
        }), _vm._v(" Khách sạn ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        var _vm$tourSelected4;
        return [_c('span', {
          staticClass: "text-info fw-600 text-break"
        }, [_vm._v(_vm._s((_vm$tourSelected4 = _vm.tourSelected) === null || _vm$tourSelected4 === void 0 ? void 0 : _vm$tourSelected4.levelNam))])];
      },
      proxy: true
    }], null, false, 1758340099)
  }) : _vm._e(), _c('TourInfoLineLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('IAmIcon', {
          attrs: {
            "icon": "clockOutline",
            "size": "20",
            "color": "green"
          }
        }), _vm._v(" Thời gian ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        var _vm$tourSelected5, _vm$tourSelected6;
        return [_c('span', {
          staticClass: "text-info fw-600 text-break"
        }, [_vm._v(_vm._s((_vm$tourSelected5 = _vm.tourSelected) === null || _vm$tourSelected5 === void 0 ? void 0 : _vm$tourSelected5.totalDay) + "N" + _vm._s((_vm$tourSelected6 = _vm.tourSelected) === null || _vm$tourSelected6 === void 0 ? void 0 : _vm$tourSelected6.totalNight) + "Đ")])];
      },
      proxy: true
    }])
  }), _c('TourInfoLineLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('IAmIcon', {
          attrs: {
            "icon": "plane",
            "size": "20",
            "color": "green"
          }
        }), _vm._v(" Phương tiện ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        var _vm$tourSelected7, _vm$tourSelected8, _vm$tourSelected9, _vm$tourSelected10;
        return [_c('span', {
          staticClass: "text-info fw-600 text-break"
        }, [_vm._v(_vm._s(_vm.$t("tour.vehiclesType.".concat((_vm$tourSelected7 = _vm.tourSelected) === null || _vm$tourSelected7 === void 0 ? void 0 : _vm$tourSelected7.vehiclesGo))) + " "), ((_vm$tourSelected8 = _vm.tourSelected) === null || _vm$tourSelected8 === void 0 ? void 0 : _vm$tourSelected8.vehiclesBack) !== ((_vm$tourSelected9 = _vm.tourSelected) === null || _vm$tourSelected9 === void 0 ? void 0 : _vm$tourSelected9.vehiclesGo) ? _c('span', [_vm._v(" - " + _vm._s(_vm.$t("tour.vehiclesType.".concat((_vm$tourSelected10 = _vm.tourSelected) === null || _vm$tourSelected10 === void 0 ? void 0 : _vm$tourSelected10.vehiclesBack))))]) : _vm._e()])];
      },
      proxy: true
    }])
  }), (_vm$tourSelected11 = _vm.tourSelected) !== null && _vm$tourSelected11 !== void 0 && _vm$tourSelected11.startDateText ? _c('TourInfoLineLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('IAmIcon', {
          attrs: {
            "icon": "calendar",
            "size": "20",
            "color": "green"
          }
        }), _vm._v(" Ngày đi ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        var _vm$tourSelected12;
        return [_c('span', {
          staticClass: "text-info fw-600 text-break"
        }, [_vm._v(_vm._s((_vm$tourSelected12 = _vm.tourSelected) === null || _vm$tourSelected12 === void 0 ? void 0 : _vm$tourSelected12.startDateText))])];
      },
      proxy: true
    }], null, false, 3386044389)
  }) : _vm._e(), (_vm$tourSelected13 = _vm.tourSelected) !== null && _vm$tourSelected13 !== void 0 && _vm$tourSelected13.endDateText ? _c('TourInfoLineLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('IAmIcon', {
          attrs: {
            "icon": "calendar",
            "size": "20",
            "color": "green"
          }
        }), _vm._v(" Ngày về ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        var _vm$tourSelected14;
        return [_c('span', {
          staticClass: "text-info fw-600 text-break"
        }, [_vm._v(_vm._s((_vm$tourSelected14 = _vm.tourSelected) === null || _vm$tourSelected14 === void 0 ? void 0 : _vm$tourSelected14.endDateText))])];
      },
      proxy: true
    }], null, false, 439673573)
  }) : _vm._e(), (_vm$tourSelected15 = _vm.tourSelected) !== null && _vm$tourSelected15 !== void 0 && _vm$tourSelected15.endDateText ? _c('TourInfoLineLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('IAmIcon', {
          attrs: {
            "icon": "softSeatOutline",
            "size": "20",
            "color": "green"
          }
        }), _vm._v(" Số chỗ ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        var _vm$tourSelected16, _vm$tourSelected17;
        return [_c('span', {
          class: "".concat((_vm$tourSelected16 = _vm.tourSelected) !== null && _vm$tourSelected16 !== void 0 && _vm$tourSelected16.numberTicketsAvailable ? 'text-info' : 'text-danger', " fw-600")
        }, [_vm._v(" " + _vm._s(((_vm$tourSelected17 = _vm.tourSelected) === null || _vm$tourSelected17 === void 0 ? void 0 : _vm$tourSelected17.numberTicketsAvailable) || 'Hết chỗ') + " ")])];
      },
      proxy: true
    }], null, false, 1675328869)
  }) : _vm._e(), (_vm$tourSelected18 = _vm.tourSelected) !== null && _vm$tourSelected18 !== void 0 && _vm$tourSelected18.deadlineVisa ? _c('TourInfoLineLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('IAmIcon', {
          attrs: {
            "icon": "clockOutline",
            "size": "20",
            "color": "green"
          }
        }), _vm._v(" Hạn nộp hồ sơ ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        var _vm$tourSelected19;
        return [_c('span', {
          staticClass: "text-info fw-600 text-break"
        }, [_vm._v(_vm._s((_vm$tourSelected19 = _vm.tourSelected) === null || _vm$tourSelected19 === void 0 ? void 0 : _vm$tourSelected19.deadlineVisa))])];
      },
      proxy: true
    }], null, false, 1065609456)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }