<template lang="">
  <BRow>
    <BCol
      cols="5"
      class="d-flex-between pr-25 align-items-center"
    >
      <HStack>
        <slot name="title" />
      </HStack>
      :
    </BCol>
    <BCol
      cols="7"
      class="pl-25 d-flex align-items-center"
    >
      <slot name="content" />
    </BCol>
  </BRow>
</template>
<script>
import { BCol, BRow } from 'bootstrap-vue'

export default {
  components: { BCol, BRow },
}
</script>
<style lang="">

</style>
