<template lang="">
  <div :class="customClass">
    <!-- ANCHOR - tourCode -->
    <TourInfoLineLayout v-if="tourSelected?.tourCode">
      <template #title>
        <IAmIcon
          icon="ticketLine"
          size="20"
          color="green"
        />
        Mã chương trình
      </template>
      <template #content>
        <span class="text-info fw-600 text-break">{{ tourSelected?.tourCode }}</span>
      </template>
    </TourInfoLineLayout>

    <!-- ANCHOR - defaultDepartPoint -->
    <TourInfoLineLayout v-if="defaultDepartPoint">
      <template #title>
        <IAmIcon
          icon="locationOutline"
          size="20"
          color="green"
        />
        Khởi hành
      </template>
      <template #content>
        <span class="text-info fw-600 text-break">{{ defaultDepartPoint }}</span>
      </template>
    </TourInfoLineLayout>

    <!-- ANCHOR - Khách sạn -->
    <TourInfoLineLayout v-if="tourSelected?.levelNam">
      <template #title>
        <IAmIcon
          icon="hotelLine"
          size="20"
          color="green"
        />
        Khách sạn
      </template>
      <template #content>
        <span class="text-info fw-600 text-break">{{ tourSelected?.levelNam }}</span>
      </template>
    </TourInfoLineLayout>

    <!-- ANCHOR - Khách sạn -->
    <TourInfoLineLayout>
      <template #title>
        <IAmIcon
          icon="clockOutline"
          size="20"
          color="green"
        />
        Thời gian
      </template>
      <template #content>
        <span class="text-info fw-600 text-break">{{ tourSelected?.totalDay }}N{{ tourSelected?.totalNight }}Đ</span>
      </template>
    </TourInfoLineLayout>

    <!-- ANCHOR - Phương tiện -->
    <TourInfoLineLayout>
      <template #title>
        <IAmIcon
          icon="plane"
          size="20"
          color="green"
        />
        Phương tiện
      </template>
      <template #content>
        <span class="text-info fw-600 text-break">{{ $t(`tour.vehiclesType.${tourSelected?.vehiclesGo}`) }}
          <span v-if="tourSelected?.vehiclesBack !== tourSelected?.vehiclesGo"> - {{ $t(`tour.vehiclesType.${tourSelected?.vehiclesBack}`) }}</span>
        </span>
      </template>
    </TourInfoLineLayout>

    <!-- ANCHOR - Ngày đi -->
    <TourInfoLineLayout v-if="tourSelected?.startDateText">
      <template #title>
        <IAmIcon
          icon="calendar"
          size="20"
          color="green"
        />
        Ngày đi
      </template>
      <template #content>
        <span class="text-info fw-600 text-break">{{ tourSelected?.startDateText }}</span>
      </template>
    </TourInfoLineLayout>

    <!-- ANCHOR - Ngày về -->
    <TourInfoLineLayout v-if="tourSelected?.endDateText">
      <template #title>
        <IAmIcon
          icon="calendar"
          size="20"
          color="green"
        />
        Ngày về
      </template>
      <template #content>
        <span class="text-info fw-600 text-break">{{ tourSelected?.endDateText }}</span>
      </template>
    </TourInfoLineLayout>

    <!-- ANCHOR - Số chỗ -->
    <TourInfoLineLayout v-if="tourSelected?.endDateText">
      <template #title>
        <IAmIcon
          icon="softSeatOutline"
          size="20"
          color="green"
        />
        Số chỗ
      </template>
      <template #content>
        <span :class="`${tourSelected?.numberTicketsAvailable ? 'text-info' : 'text-danger'} fw-600`">
          {{ tourSelected?.numberTicketsAvailable || 'Hết chỗ' }}
        </span>
      </template>
    </TourInfoLineLayout>

    <!-- ANCHOR - deadlineVisa -->
    <TourInfoLineLayout v-if="tourSelected?.deadlineVisa">
      <template #title>
        <IAmIcon
          icon="clockOutline"
          size="20"
          color="green"
        />
        Hạn nộp hồ sơ
      </template>
      <template #content>
        <span class="text-info fw-600 text-break">{{ tourSelected?.deadlineVisa }}</span>
      </template>
    </TourInfoLineLayout>
  </div>
</template>
<script>

import { defaultDepartPoint } from '../useTourHandle'
import TourInfoLineLayout from './TourInfoLineLayout.vue'

export default {
  components: {
    TourInfoLineLayout,
  },
  props: {
    tourSelected: { type: Object, default: null },
    customClass: { type: String, default: '' },
  },
  setup() {
    return {
      defaultDepartPoint,
    }
  },
}
</script>
<style lang="">

</style>
